import { Flex, Grid, GridItem, Tag, Text,Icon, Stack, Menu, MenuButton, IconButton, MenuList, MenuItem, Spacer, Box, Tooltip } from "@chakra-ui/react"; 
import { LuCog, LuGripVertical, LuSettings } from "react-icons/lu";

 

export default function ListBox({titles=[],dados=[],cmd=[]}){   
    if(dados.length == 0){
        return ( 
            <Stack  w={'100%'}  gap={{base:'12px',md:'0'}}>
                <Flex display={{base:'none',md:'flex'}}  w={'100%'} pl='12px'  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}   minH={'44px'} align={'center'}   >
                     <Text>Nenhum resultado encontrado</Text>
                </Flex>  
            </Stack> 
        )
    }else{
        return <Stack  w={'100%'}  gap={{base:'12px',md:'0'}}>
                    <Grid   w={'100%'}  templateColumns={{base:'repeat(1, 1fr)',sm:'repeat(2, 1fr)' ,md:'repeat(1, 1fr)',lg:'repeat(2, 1fr)', 'xl':'repeat(3, 1fr)','2xl':'repeat(4, 1fr)'}} gap={'16px'}  >
                        {dados.map((c,i)=>{ 
                            return  ( 
                                <GridItem  position={'relative'}   key={'reg'+i}  colSpan={1} fontWeight={'bold'} borderRadius={'4px'}    textAlign={'center'}  >
                                    <Box   position={'absolute'} bg={'rgba(137,137,137,0.1)'} backdropFilter='blur(60px)' w={'100%'} h={'100%'}></Box>
                                    <Flex    position={'relative'}  w='100%'  align={'center'} justify={'space-between'} gap={'8px'}  p={'8px 16px 0px 16px'} >
                                        <Tooltip label={c.title}  bg={'rgba(137,137,137,0.1)'} backdropFilter='blur(60px)' color={'textAdmin.300'}>
                                            <Text   noOfLines={1} textAlign={'left'} color={'textAdmin.100'} fontSize={'12px'}>{c.title}</Text>
                                        </Tooltip>
                                        <Spacer/>
                                        {c.status}
                                        <Menu   >
                                            <MenuButton 
                                                as={IconButton}
                                                aria-label='Options'
                                                    px={'8px'}
                                                fontSize={'20px'}
                                                icon={<LuSettings />}
                                                variant='outline'
                                                border={'0px !important'}
                                            />
                                                <MenuList  bg={'rgba(137,137,137,0.1)'} backdropFilter='blur(60px)' px='5px'  > 
                                                    {cmd.map((m,p)=>{ 
                                                        var add = false;
                                                        if(typeof m.situa == 'undefined'){
                                                            add = true;
                                                        }else{
                                                            if(m.situa == c.situa){
                                                                add= true;
                                                            }
                                                        }
                                                        if(add){
                                                            return ( <MenuItem key={'menu'+p} icon={(typeof m.icon !=='undefined'?m.icon:null)}  onClick={()=>{ m.click(c); }}  bg='transparent' _hover={{bg:'rgba(0,0,0,0.8)'}} borderRadius={'4px'} >{m.name}</MenuItem>)
                                                        }else{
                                                            return null;
                                                        }
                                                    })}  
                                                </MenuList>
                                        </Menu>
                                    </Flex>
                                    <Stack position={'relative'}  gap={'4px'} px='16px' pb={'16px'} >
                                        {titles.map((t,pos)=>{  
                                            return (
                                                <Flex key={'campo_'+t.campo+'_'+i+'_'+pos} position={'relative'}  w={'100%'} justify={'space-between'} >
                                                    <Text  color='textLight.200' _dark={{color:'textDark.200'}}  fontFamily={'InterBold'} noOfLines={'1'} fontSize={{base:'10px',md:'12px'}}  minW={'max-content'}>{t.name}</Text> 
                                                    <Flex  color='textLight.200' _dark={{color:'textDark.100'}}   noOfLines={'1'} fontSize={{base:'10px',md:'12px'}} fontWeight={'500'}   textAlign={{base:'right',md:'center'}} >{c[t.campo]}</Flex>
                                                </Flex> 
                                            )
                                        })} 
                                        
                                            {c.sub!=null?
                                            <Flex    w={'100%'} borderTop={'1px solid'} borderColor={'textAdmin.200'} align={'center'} justify={'space-between'} pt={'16px'} mt={'8px'}>
                                                {c.sub}
                                            </Flex>
                                            :null}
                                            
                                    </Stack>
                                    

                                </GridItem>
                            )
                        })}    
                        {/* {dados.map((c,i)=>{ 
                            return <GridItem  key={'reg'+i}  colSpan={1} fontWeight={'bold'}  color='textLight.100' _dark={{color:'textDark.100'}}  textAlign={'center'}  > 
                                <Flex w='100%'>
                                    <Text>Nome</Text>
                                    <Tag colorScheme="gree">Label</Tag>
                                    <Icon as={LuCog}></Icon>
                                </Flex>
                            </GridItem>
                        })} */}
                    
                        {/*  */}
                    </Grid>
                </Stack> 
    }
}