import { useRef, useState } from "react"; 
import { Flex, HStack, Modal, ModalBody, ModalContent, ModalOverlay, PinInput, PinInputField, Stack, Text ,Icon, ModalFooter, ModalCloseButton, Button, IconButton, useToast} from "@chakra-ui/react";
import { TextK1 } from "../text/TextKalyst"; 
import { MdSecurity } from "react-icons/md";
import { sendFormSocket } from "../session/SessionAdmin";
import { hexToRGB } from "../Function";
import { color } from "../../theme/theme";
import { HK5 } from "../heading/HeadingKalyst";
import { ButtonKalyst } from "../button/ButtonKalyst";
import { FaPaste } from "react-icons/fa";
import { LinkK } from "../button/LinkKalyst";

export default function Box2Fa({onClose=()=>{},icon = null,titulo='Acessar Conta',mensagem,variant='',callback=()=>{},onSubmit =null}){ 
    const [view,    setView]    =   useState(false);
    const [sendCode,    setSendCode]    =   useState(''); 
    const toast = useToast();
    var sendDados=(code)=>{
        if(onSubmit == null){
            sendFormSocket({ modulo:'user',cmd:'valid2fa',form:{code:code},callback:(result)=>{  
                console.log(result);
                if(result.erro){
                    toast({
                        title:result.titulo,
                        description: result.mensagem[0].mensagem,
                        status: 'error',
                        position:'top-right',
                        duration: 6000,
                        isClosable: true,
                    });
                }else{
                    callback();  
                };
            }}); 
        }else{  
            onSubmit({code:code}); 
        }
    }
    return (
        <Modal isCentered isOpen={true}  size={{base:'sm',sm:'md'}}  onClose={()=>{ 
            if(typeof onClose == 'function'){
                onClose();
            } 
        }} >
            <ModalOverlay  bg={'rgba('+hexToRGB(color.bgMainDark)+',0.5)' } _dark={{bg:'rgba('+hexToRGB(color.bgMainDark)+',0.5)'}} backdropFilter='blur(10px) hue-rotate(30deg)' /> 
            <ModalContent w={'100%'}  bg={'bgDialogLight.100'}  _dark={{bg:'bgDialogDark.100'}} py='20px' >
                <ModalBody h='full'  alignContent='center'  >
                    <Stack w={'full'} h='full' align={'center'} justify='center'>
                        <Flex  color={'textLight.100'} _dark={{color:'textDark.100'}}>{icon!=null?icon: <Icon as={MdSecurity} h={{base:'50px'}} w={{base:'50px'}} color='green.300' />}</Flex>
                        <HK5  color={'textLight.100'} _dark={{color:'textDark.100'}}>{titulo}</HK5>
                        <Stack  w={'full'} justify='center'>
                            <Text color={'textAdmin.300'} fontWeight='bold'  textAlign='center'>{mensagem}</Text>
                            <TextK1  textAlign='center'>Digite sua chave de segurança</TextK1>  
                            <HStack w={'full'} pt='20px'  align={'center'} justify='center'> 
                                <PinInput manageFocus={true} type='alphanumeric' max focus value={sendCode}    onChange={(v)=>{ 
                                    if(v.length==6){
                                        setView(true); 
                                    }else{
                                        setView(false);
                                    }
                                    setSendCode(v.replace(/[^0-9]/gi, ''));
                                }}  onComplete={async (v)=>{    
                                    sendDados(v); 
                                }} size={{base:'sm',lg:'lg'}} >
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}} />
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                    <PinInputField type="number" bg='lightTema1.200' color={variant=='admin'?'textAdmin.300':'darkTema1.300'} border='2px solid' borderRadius={'8px'}  fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                </PinInput>
                                <IconButton onClick={async ()=>{
                                     try {
                                        const text = (await navigator.clipboard.readText()).replace(/[^0-9]/gi, '');   
                                        if(text.substring(0, 6).length==6){
                                            setView(true); 
                                        }else{
                                            setView(false);
                                        }
                                        setSendCode(text.substring(0, 6));
                                    } catch (error) {
                                        
                                    } 
                                }} minW={{base:'32px',lg:'48px'}} h={{base:'32px',lg:'48px'}} borderRadius={{base:'2px',lg:'8px'}} variant={variant} icon={<FaPaste/>}></IconButton>
                            </HStack> 
                            <Flex w={'100%'} justify={'flex-end'} mt='15px'>
                                <LinkK   onClick={()=>{
                                    setView(false); 
                                    setSendCode(''); 
                                }}>
                                    Limpar
                                </LinkK>
                            </Flex>
                            
                        </Stack> 
                    </Stack> 
                </ModalBody>
                 <ModalFooter   >
                    <Stack direction={'row'} gap={'10px'} w={'100%'}  align='center' justify='center'> 
                        <ButtonKalyst     opacity={view?'1':'0.5'}  onClick={()=>{
                           sendDados(sendCode); 
                        }} variant={typeof variant=='undefined'?"solid":variant} >Validar</ButtonKalyst>
                        <ButtonKalyst  onClick={()=>{ 
                            if(typeof onClose == 'function'){
                                onClose();
                            } 
                        }} colorScheme='red' variant={'ghost'}>Cancelar</ButtonKalyst>
                    </Stack>
                </ModalFooter> 
                <ModalCloseButton top={'20px'} right='20px'  color={'textLight.100'}  _dark={{color:'textDark.100'}} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/> 
            </ModalContent>
        </Modal>
    )
}