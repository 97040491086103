import React, { useState }  from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './theme/reportWebVitals';
import Layout from './layout/Layouts'; 
import PageConfig from './theme/PageConfig';
import ErrorBoundary from './theme/ErrorBoundary';
 
 
try {
  ReactDOM.createRoot(document.getElementById("root")).render( 
    <HelmetProvider> 
        <PageConfig> 
            <BrowserRouter> 
                <Routes> 
                    <Route path="/*" element={<Layout/>}  />   
                </Routes>
            </BrowserRouter> 
        </PageConfig>
    </HelmetProvider> 
  )   
} catch (error) {
  //console.log(error.message)
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
