import { Flex, Skeleton, Stack} from "@chakra-ui/react";
import { PageHK, SubBHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendFormAdmin"; 
import { LinkK } from "../../../components/button/LinkKalyst";
import { useState } from "react";
import SelectK from "../../../components/select/SelectKalyst"; 
import { InputUploadAPI } from "../../../components/input/InputUploadAPI";
import { InputUploadRSA } from "../../../components/input/InputUploadRSA";

export default function FormAdminAcquirer( {open,setOpen,edit = null,setEdit,subs,regsubs= []}){  
   
    const [load,setLoadD] = useState(1);
    const [base,setBase] = useState(null);
    const [code,setCode] = useState(null);
    const [reload,setReload] = useState(false);  
    
    let dados       =   {}; 
    if(edit!=null){
        regsubs.forEach((v)=>{
            if(v.id == edit){
                dados = v;
                if(code == null){
                    setCode(v.code);
                    setBase(v);
                    setReload(true);
                }
            }
        }) 
    } 
    let selectSubs  =   [];
    let subDados    =   {}; 
    subs.forEach((v)=>{
        subDados[v.code] = v; 
        var add = true;
        regsubs.forEach((api)=>{
            if(api.code == v.code){
                add =false;
            }
        });
        if(add){
            selectSubs.push({value:v.code,name:v.name});
        }
        
    }) 
  
    if(!reload){
        var sendOB = {
            obg:{},
            val:{},
            grl:{}
        }

        if(code  !=  null && code  !=  ''  ){ 
            subDados[code].chaves.forEach((n)=>{ 
                sendOB.obg[n.name] = string().required(n.title+' é obrigatório!');
                if(base!=null){
                    sendOB.val[n.name] =  base[n.name];  
                }  
            }); 
            if(base!=null){  
                sendOB.grl = base
            }   
        } 
        var send ={
            code: code
        }
        if(Object.keys(dados).length>0){
            send.id =dados.id
        } 
        return (
             <> 
                <PageHK   textAlign='left'>  {Object.keys(dados).length==0?'Registrar Adquirente':'Editar API - '+ dados.nome}</PageHK> 
                <Stack pt='20px'> 
                {Object.keys(dados).length==0?
                
                <SelectK loaded={load} initial={{code:code!=null?code:''}} label={'Adquirente'} w="100%" onChange={(e)=>{   
                    setCode(e.target.value); 
                    if(e.target.value!=''){ 
                        regsubs.forEach((v)=>{ 
                            if(v.code == e.target.value){
                                setBase(v); 
                            } 
                        }); 
                    }else{
                        edit = null;
                        setBase(null);
                    } 
                    setReload(true);
                }}  placeholder="Selecione" value={selectSubs} name='code' ></SelectK> :
                null}
                    
                    {
                        code    !=  null && code  !=  '' ?
                        <SendForm variantBT='admin'  dados = {{modulo:'acquirer',cmd:'saveAdq',form:send}}  
                            initial={ sendOB.val }  
                            callback={(result)=>{  
                                setOpen(false); 
                            }}
                            gap={'10px'}
                        > 
                        {subDados[code].rsa?
                            <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}> 
                                <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}> 
                                    <InputUploadRSA title={''} adq={code}  name={'rsa'} value={ send.id } /> 
                                    {/* <Flex w='full' gap={'10px'}> 
                                        <Flex w='100%' gap={'10px'}>
                                            <InputK  type='integer'     label="Dia de  Vencimento"      placeholder="dia"       max='30'        name="saqueDia"                        focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='integer'     label="Pedidos Mínimos"         placeholder="Dias"      name="saqueNum"                                  focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='decimal'     label="Taxa Mensal"             placeholder="R$"        name="saqueMen"  sigla={'R$'}   step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex> */}
                                </Stack>
                            </Stack>:
                                null
                            }
                            <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}> 
                                <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>API Integração:</SubBHK> 
                                    <Flex gap={'20px'} pt='10px'>
                                        {subDados[code].chaves.map((campo,i)=>{
                                            if(i <2){
                                                if(campo.type!='file'){
                                                    return<InputK key={code+'-'+campo.name} type={campo.type} label={campo.title} variantBT='admin'  name={campo.name} placeholder={campo.title}  initial={ sendOB.val }     autoComplete={false}/>
                                                } 
                                            }else{
                                                return null;
                                            } 
                                        })} 
                                    </Flex>
                                   
                                    {subDados[code].chaves.length>2?
                                        <Flex gap={'20px'}>
                                            {subDados[code].chaves.map((campo,i)=>{
                                                if(i<2){
                                                    return null;
                                                }else{   
                                                    if(campo.type!='file'){
                                                        return<InputK key={code+'-'+campo.name} type={campo.type} label={campo.title} variantBT='admin'  name={campo.name} placeholder={campo.title}  initial={ sendOB.val }     autoComplete={false}/>
                                                    } 
                                                } 
                                            })} 
                                        </Flex>:null
                                    }
                                    {subDados[code].chaves.length>0?
                                        <Flex gap={'20px'}>
                                            {subDados[code].chaves.map((campo,i)=>{ 
                                                var viewCampo = null;
                                                switch (campo.type) {
                                                    case 'file':
                                                        viewCampo =   <InputUploadAPI key={code+'-'+campo.name}  title={campo.title}  name={campo.name} value={ sendOB.val[campo.name] } />
                                                    break;  
                                                }
                                                return  viewCampo; 
                                            })} 
                                        </Flex>:null
                                    }
                            </Stack>  
                            {subDados[code].pix?
                                <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                                    <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Adquirente PIX:</SubBHK>
                                    <Flex w='full' gap={'10px'}> 
                                        <Flex w='50%' gap={'10px'}>
                                            <InputK  type='decimal'     label="Fixo"            placeholder="R$"        name="pixFixo"  sigla={'R$'}   step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"         name="pixPor"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                        <Flex w='50%' gap={'10px'}>
                                            <InputK  type='porcentagem' label="Retenção"        placeholder="%"         name="pixRes"   max="100"                    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"      name="pixResD"                                  focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex>
                                </Stack>:
                                null
                            }
                            {subDados[code].card?
                                <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                                    <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Adquirente Card:</SubBHK>
                                    <Flex w='full' gap={'10px'}> 
                                        <Flex w='100%' gap={'10px'}>
                                            <InputK  type='decimal'     label="Fixo"            placeholder="R$"       name="cardFixo"  sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="Retenção"        placeholder="%"        name="cardRes"  max="100"           step='0,00'    focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"     name="cardResD"                                  focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex> 
                                    <Flex w='full' gap={'10px'}> 
                                        <Flex w='50%' gap={'10px'}> 
                                            <InputK  type='porcentagem'     label="1X"      placeholder="00,00%"    name="p1"   step='0,00'      w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="2X"      placeholder="00,00%"    name="p2"   step='0,00'      w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="3X"      placeholder="00,00%"    name="p3"   step='0,00'      w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                        <Flex w='50%' gap={'10px'}> 
                                            <InputK  type='porcentagem'     label="4X"      placeholder="00,00%"    name="p4"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="5X"      placeholder="00,00%"    name="p5"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="6X"      placeholder="00,00%"    name="p6"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex>
                                    <Flex w='full' gap={'10px'}> 
                                        <Flex w='50%' gap={'10px'}> 
                                            <InputK  type='porcentagem'     label="7X"      placeholder="00,00%"    name="p7"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="8X"      placeholder="00,00%"    name="p8"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="9X"      placeholder="00,00%"    name="p9"   step='0,00'     w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                        <Flex w='50%' gap={'10px'}> 
                                            <InputK  type='porcentagem'     label="10X"     placeholder="00,00%"    name="p10"  step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="11X"     placeholder="00,00%"    name="p11"  step='0,00'     w='100%' initial={sendOB.grl}/>
                                            <InputK  type='porcentagem'     label="12X"     placeholder="00,00%"    name="p12"  step='0,00'     w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex>
                                </Stack>:null
                            }
                            {subDados[code].boleto?
                            <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                                <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Adquirente Boleto:</SubBHK>
                                <Flex w='full' gap={'10px'}> 
                                    <Flex w='50%' gap={'10px'}>
                                        <InputK  type='decimal'     label="Fixo"            placeholder="R$"      name="boletoFixo"  sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"             name="boletoPor"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                    </Flex>
                                    <Flex w='50%' gap={'10px'}>
                                        <InputK  type='porcentagem' label="Retenção"        placeholder="%"         name="boletoRes"   max="100"          step='0,00'  focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"      name="boletoResD"                                   focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                    </Flex>
                                </Flex>
                            </Stack>:null}

                            <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                                {code== null?
                                    <Skeleton>
                                        <ButtonKalyst  variant={'admin'}>
                                            {base==null?'Registrar Adquirente':'Salvar Adquirente'}
                                        </ButtonKalyst>
                                    </Skeleton>
                                    :
                                    <ButtonKalyst  variant={'admin'}>
                                        {base==null?'Registrar Adquirente':'Salvar Adquirente'}
                                    </ButtonKalyst> 
                                }
                                
                                <LinkK onClick={()=>{
                                    setOpen(false);
                                }}>
                                    Cancelar
                                </LinkK>
                            </Flex>  
                        </SendForm>:   null  
                    }
                    
                </Stack>  
            </> 
        )
    }else{

        setTimeout(()=>{
            setReload(false)
        },0);
        return null;
    }
    
}




// import { Flex, HStack, SimpleGrid } from "@chakra-ui/react";
// import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
// import { InputK } from "../../../components/input/InputKalyst";
// import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
// import { object, string } from "yup";
// import { SendForm } from "../../../components/form/SendForm";
// import { TextK1 } from "../../../components/text/TextKalyst";
// import { LinkK } from "../../../components/button/LinkKalyst";
// import { useRef, useState } from "react";

// export default function FormClint( {open,setOpen,dados = {}}){ 
//     const [redirect,setRedirect] = useState(null);  
//     const end = useRef(null);
//     const [bairro,setBairro]    = useState((typeof dados.bairro!=='undefined'?dados.bairro:'')); 
//     const [cidade,setCidade]    = useState((typeof dados.cidade!=='undefined'?dados.cidade:''));  
    
//     return (
//         <SendForm  dados = {{modulo:'user',cmd:'register'}}  
//             initial={ dados }

//             schema={ 
//                 object({ 
//                     nome:       string().required('Razão Social é obrigatório!'),
//                     fantasia:   string().required('Nome Fantasia é obrigatório!'),
//                     email:      string().required('E-mail é obrigatório!'), 
//                     cnpj:       string().required('CNPJ é obrigatório!'), 
//                     telefone:   string().required('Telefone é obrigatório!'), 
//                 })
//             } 
//             callback={(result)=>{ 
              
//             }}
//             gap={'10px'}
//             > 
            
//         </SendForm>
//     )
// }