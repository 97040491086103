import { defineStyle, defineStyleConfig } from "@chakra-ui/react"
import { getWebsite } from "./PageConfig";

 

export let color ={ };
export function setDados(dados){ 
    color = dados.color;
    const solid = defineStyle({ 
      borderRadius: 0, // remove the border radius
      fontWeight: 'semibold', // change the font weight 
      
      bg:color.bgBrandPrimary,
      color:'gray.100',
      // textShadow:'1px 1px 1px rgba(0,0,0,0.4)',
      borderColor:color.bgBrandPrimary,
      _hover:{
        bg:color.bgBrandDark
      },
      _selection:{ 
        bg:color.bgBrandVariant
      },
      minW:'full',
      _dark:{  
        color:'lightTema1.100'
      }
    })
    const admin = defineStyle({ 
      borderRadius: 0, // remove the border radius
      fontWeight: 'semibold', // change the font weight 
      
      bg:'bgAdmin.300',
      color:'textAdmin.100', 
      borderColor:'bgAdmin.300',
      _hover:{
        bg:'bgAdmin.800'
      },
      _selection:{ 
        bg:'bgAdmin.800'
      },
      _dark:{},
      minW:'full'
    })
    const input = defineStyle({   
      fontFamily:'Inter',
      fontWeight: '0 !important',
      borderRadius: 0, // remove the border radius 
      p:'0 !important', 
      pl:{base:'12px !important',md:'16px !important'},    
      color:'textLight.100', 
      _dark:{color:'textDark.100'},
      _hover:{ },
      _selection:{} 
    })
    const inputAdmin = defineStyle({   
      fontFamily:'Inter',
      fontWeight: '0 !important',
      borderRadius: 0, // remove the border radius 
      p:'0 !important', 
      pl:{base:'12px !important',md:'16px !important'},    
      color:'textAdmin.100', 
      _dark:{color:'textAdmin.100'},
      _hover:{ },
      _selection:{} 
    })
    const outline = defineStyle({
      border: '1px solid', // change the appearance of the border
      borderRadius: 0, // remove the border radius
      fontWeight: 'semibold', // change the font weight 
      bg:'transparent',
      color:'.100',
      borderColor:'darkTema1.100',
      minW:'auto',
      _dark:{
        border: '1px solid', 
        color:'lightTema1.100'
      }
    })
    themeKalyst =  (
      {
      config: {
        initialColorMode: dados.theme,
        useSystemColorMode: false
      },
      colors: {
        bgLoginLight:{
          100:'black',
          200:color.bgBrandDark,
          300:color.bgBrandPrimary 
        }, 
        bgLoginDark:{
          100:'black',
          200:color.bgBrandDark,
          300:color.bgBrandPrimary 
        },
    
        bgMenuLight:{  
          100:color.bgVariantLight, 
          200:color.bgMainLight,
          300:color.bgBrandPrimary 
        },
        bgMenuDark:{ 
          100:color.bgMainDark,
          200:color.bgVariantDark,
          300:color.bgBrandPrimary  
        }, 
        bgDialogDark:{
          100:'black',
          200:color.bgBrandDark,
          300:color.bgBrandPrimary 
        }, 
        bgDialogLight:{
          100:'#f1f1f1',
          200:color.bgBrandDark,
          300:color.bgBrandPrimary 
        }, 
        textLight:{
          100:color.txMainLight,
          200:color.txVariantLight,
          300:color.bgBrandPrimary
        },
        textDark:{
          100:color.txMainDark,
          200:color.txVariantDark,
          300:color.bgBrandPrimary
        },
        lightTema1: {
          100: color.bgMainLight, 
          200: color.bgVariantLight,
          300: color.bgBrandPrimary,
          400: "#E7E7E7",
          500: "#000000",
          600: "#000000",
          700: "#000000",
          800: "#000000",
          900: "#000000" 
        },
        darkTema1: {
          100: color.bgMainDark, 
          200: color.bgVariantDark,
          300: color.bgBrandPrimary,
          400: "#D1D1D1",
          500: "#000000",
          600: "#000000",
          700: "#000000",
          800: "#000000",
          900: "#ffffff", 
        }, 
        green:{
          100:'#22C55E',
          200:'#2BE05D',
          300:'#22C55E',
          400:'#47784D',
        }, 
        purple:{
          100:'#F1F1F3',
          200:'#913AF8',
          300:'#913AF8',
          400:'#913AF8',
        }, 
        pix:{ 
          200: color.chartPix  , 
          500:color.chartPix,
        },
        lojista:{ 
          200:color.bgBrandPrimary, 
          500:color.bgBrandDark,
        }, 
        card:{ 
          200:  color.chartCard, 
          500:  color.chartCard,
        } , 
        boleto:{ 
          200:  color.chartBoleto, 
          500:  color.chartBoleto,
        },
        //admin
        textAdmin: {
          100: '#F1F1F3', 
          200: '#F6F6F6',
          300: '#913AF8',
          400: "#5D5D5D",
          500: "#888888",
          600: "#000000",
          700: "#000000",
          800: "#000000",
          900: "#000000" 
        },
        bgAdmin: {
          100: '#131313', 
          200: '#1C1C1C',
          300: '#913AF8',
          400: "#F6F6F6",
          500: "#888888",
          600: "#000000",
          700: "#000000",
          800: "#6227AB",
          900: "#FFFFFF", 
        }, 
      },
      components:{  
        Button: defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter' 
          },
          variants: { outline,solid,input,admin}, 
        }),
        Heading:defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter',
            color:color.txMainLight
          }
        }),
        Label:defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter',
            color:color.txMainLight
          }
        }),
        FormLabel:defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter',
            color:color.txMainLight 
          }
        }),
        Text:defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter',
            color:color.txMainLight 
          }
        }),
        Input:defineStyleConfig({
          baseStyle: {
            fontFamily:'Inter', 
            color:color.txMainLight 
          },
          variants: {inputAdmin},
        })
      }
    }
  )
}


export let  themeKalyst =  {}


export const lineChartDataDashboard = [
  {
    name: "Compras",
    data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
  }
];

export const lineChartOptionsDashboard = (colorMode,categories)=>{  
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: colorMode,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories,
      labels: {
        style: {
          colors: colorMode == 'dark'?"#ffffff":'#000000',
          fontSize: "14px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: { 
          colors: colorMode == 'dark'?"#ffffff":'#000000',
          fontSize: "14px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: colorMode,
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: [color['chart_'+colorMode+'1'], color['chart_'+colorMode+'2']],
    },
    colors: [color['chart_'+colorMode+'1'], color['chart_'+colorMode+'2']],
  }
};

export const barChartDataCharts2 = [
  {
    name: "PIX",
    data: [60],
  },
  {
    name: "Cartão",
    data: [35],
  },
  {
    name: "Boleto",
    data: [5],
  }
];

export function barChartOptionsCharts2(colorMode){
  return {
    chart: {
      toolbar: {
        show: false,
      },
    }, 
    innerHeight:'190px', 
    colors: [color.bgBrandPrimary, "#FF7F00", "#FF0000"],
    stroke: {
      width: 0
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {   
          return `<div style="padding:10px"><b>${w.config.series[seriesIndex].name}</b><br/> R$ ${((w.config.series[seriesIndex].data[0]).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})}</div>`;
      },
      style: {
        fontSize: "12px",
        
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
      theme: "dark", 
    }, 
    xaxis: {
      categories: [""],
      show: false,
      labels: {
        show: false,
        style: {
          colors: [color.bgBrandPrimary],
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: color.bgBrandPrimary,
          fontSize: "10px",
        },
      },
    },
    legend: {
      labels: {
        colors: [color.bgBrandPrimary, "#FF7F00", "#FF0000"],
      }
    },
    grid: { 
      strokeDashArray: 0, 
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: { 
      type: "solid",
      colors: [color.bgBrandPrimary, "#FF7F00", "#FF0000"],
    },
    
    dataLabels: { 
      formatter: function(context, chart_obj) { 
        try {
          return  ` ${(parseFloat(chart_obj.globals.seriesPercent[chart_obj.seriesIndex]).toFixed(2).toLocaleString('pt-BR',{minimumFractionDigits:2}))}`+'%'; 
        } catch (error) {
          return   0+'%';  
        }
      },
      style: {
        colors: [color[colorMode=='dark'?'txMainDark':'txMainLight'] ,color[colorMode=='dark'?'txMainDark':'txMainLight'],color[colorMode=='dark'?'txMainDark':'txMainLight']],
        
      },
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: "60px",  
      
      }
    },
    stroke: {
      width:0,
      colors: ["transparent"]
    },
  };
};


export function donutChartOptionsCharts1(){ 
  return {
    series: [50, 40, 10, 0, 0],
    labels: ["Pix", "Cartão", "Boleto"],
    colors: ["#000000", "#000000", "#000000"],
    chart: {
      width: "100%",
    },
    stroke: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
  
    fill: {
      colors:
      [color.chartPix ,color.chartCard,color.chartBoleto] 
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) { 
        // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if(JSON.stringify(series)=='[1,1,1]'){
          return '<b>'+ w.config.labels[seriesIndex]+'</b>  0%';
        }else{
          return  '<b>'+w.config.labels[seriesIndex]+'</b> '+((series[seriesIndex]).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}) +'%' ;
        }
        
      },
      enabled: true,
      theme: "dark",
    },
  }
};

export const donutChartDataCharts1 = [60, 30, 10];


export const pieChartOptionsCharts1 = {
  labels: ["Dev.to", "Creative Tim", "Github"],
  colors: ["#582CFF", "#3F1AD1", "#35199F"],
  chart: {
    width: "100%",
    stroke: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  stroke: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#582CFF", "#3F1AD1", "#35199F"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};